.width-full{
width: -moz-available !important;
}

.resume-content{
    width: 100%;
    height: 500px;
}

.bttn-container{
    float: right;
}

.bttn-customize{
    border-radius: 10px;
    text-decoration: none;
    background-color: white;
    color: black !important;
    font: 16px/30px 'opensans-bold', sans-serif;
    display: inline-block;
    text-decoration: none;
    letter-spacing: 0;
    color: #fff;
    padding: 12px 20px;
    margin-bottom: 18px;
    border: none;
    cursor: pointer;
    height: auto;
}
.bttn-customize:hover{
    border-radius: 10px;
    background-color: #3d4145;
    color: white !important;
}